import { createAction, props } from '@ngrx/store';
import { SubmitState } from './index.reducers';

export const setPackage = createAction(
  '[Package] SetPackage',
  props<{ pkg: string }>(),
);

export const addFlight = createAction(
  '[Flight] AddFlight',
  props<{ flight: any }>(),
);
export const removeFlight = createAction(
  '[Flight] RemoveFlight',
  props<{ index: number }>(),
);
export const updateFlight = createAction(
  '[Flight] UpdateFlight',
  props<{ index: number; flight: any }>(),
);

export const loadFlightsAction = createAction(
  '[Flight] LoadFlightsAction',
  props<{ flightNumber: string; date: Date; index: number }>(),
);
export const flightRequestSuccess = createAction(
  '[Flight] FlightRequestSuccess',
  props<{ index: number; flights: any[] }>(),
);
export const flightReuestError = createAction(
  '[Flight] flightReuestError',
  props<{ index: number; error: string }>(),
);

export const updateMainPassenger = createAction(
  '[Passenger] SetMainPassenger',
  props<{ passenger: any }>(),
);

export const addAdditionalPassenger = createAction(
  '[Passenger] AddAdditionalPassenger',
  props<{ passenger: any }>(),
);
export const removeAdditionalPassenger = createAction(
  '[Passenger] RemoveAdditionalPassenger',
  props<{ index: number }>(),
);
export const updateAdditionalPassenger = createAction(
  '[Passenger] UpdateAdditionalPassenger',
  props<{ index: number; passenger: any }>(),
);

export const submitInsuranceOutcome = createAction(
  '[Flight] SubmitInsuranceOutcome',
  props<{
    submit: SubmitState;
  }>(),
);

export const submitInsuranceRequest = createAction(
  '[Flight] submitInsuranceRequest',
);

export const highlightSection = createAction(
  '[Flight] highlightSection',
  props<{ currentHighlightedSection: number }>(),
);

export const acceptAllTerms = createAction(
  '[Flight] allTermsAccepted',
  props<{ allTermsAccepted: boolean }>(),
);
