import { Component, OnInit } from '@angular/core';
import { InsuranceService } from '../../insurance/insurance.service';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-middle',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: './middle.component.html',
  styleUrl: './middle.component.scss',
})
export class MiddleComponent implements OnInit {
  isHovered = false;

  constructor(private readonly insuranceService: InsuranceService) {}

  ngOnInit() {}

  getShortDate(departure: string) {
    return departure.split('T')[0].slice(5, 10).replace('-', '.');
  }

  getShortTime(departure: string) {
    return departure.split('T')[1].slice(0, 5);
  }

  getStatus(outcome: string) {
    switch (outcome) {
      case 'LANDED_ON_TIME':
        return 'Landed on time';
      case 'CANCELLED':
        return 'Cancelled';
      case 'DIVERTED':
        return 'Diverted';
      case 'DELAYED':
        return 'Delayed';
      case 'NO_INFO_YET':
        return 'Scheduled';
    }
    console.error('Unknown outcome', outcome);
    return 'Unknown';
  }

  onMouseEnter(): void {
    this.isHovered = true;
  }

  onMouseLeave(): void {
    this.isHovered = false;
  }

  get flights() {
    return this.insuranceService.insurance?.flights;
  }

  get isLoading() {
    if (!this.flights?.length) {
      return true;
    }
    return false;
  }
}
