import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { InsuranceService } from '../insurance.service';
import { DialogComponent } from '../../dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment as env } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { setPackage } from '../../index.actions';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, DialogComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  isRounded = false;
  isHovered = false;

  store = inject(Store);
  package$ = this.store.select('package');

  constructor(
    readonly insuranceService: InsuranceService,
    readonly dialog: MatDialog,
  ) {}

  get isMaxi$() {
    return this.package$.pipe(map((pkg) => pkg === 'MAXI'));
  }

  get isBasic$() {
    return this.package$.pipe(map((pkg) => pkg === 'BASIC'));
  }

  selectPackage(pkg: string) {
    console.log('package selected', pkg);
    this.store.dispatch(setPackage({ pkg }));
  }

  openDialog() {
    this.dialog.open(DialogComponent, {
      data: {
        title: 'Please, choose the MAXI package to continue the demo!',
        subtitle: 'Only the MAXI package contains Flight Disruption!',
      },
    });
  }

  onMouseEnter(): void {
    this.isHovered = true;
  }

  onMouseLeave(): void {
    this.isHovered = false;
  }

  get isBranded() {
    return env.branded;
  }
}
