<div class="container flex-row">
  <div class="content flex-column">
    <img src="assets/success-icon.svg" alt="">
    <div class="title">
      Success!
    </div>
    <div class="horizontal-line"></div>
    <div class="sub-title">This is your demo insurance number:</div>
    <div class="insurance-number">{{insuranceNumber}}</div>
    <div class="sub-title">Your policy is arriving soon to your email!</div>
  </div>
</div>
