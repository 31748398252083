<div class="bottom-container flex-column">
  <div class="flex-row gap-60 columns">
    <div
      class="column flex-column gap-10"
      *ngIf="(validFlights | async).length > 0 && (package$ | async) === 'MAXI'"
    >
      <div class="th">Flights</div>
      <ng-container *ngFor="let flight of validFlights | async">
        <div class="td">{{ flight.flightNumberInput.toUpperCase() }}</div>
      </ng-container>
    </div>
    <div class="column flex-column gap-10">
      <div class="th">Insured</div>
      <div class="td">{{ (validPassengers | async) + 1 }}</div>
    </div>
    <div class="column flex-column gap-10">
      <div class="th">Policy</div>
      <div class="td">{{ policyDescription | async }}</div>
    </div>
  </div>
  <div class="horizontal-line"></div>
  <div class="consents flex-column gap-20">
    <div class="consent-1 flex-row gap-10" (click)="selectAll()">
      <input type="checkbox" [checked]="allTermsAccepted$ | async" />
      <div>Accept and download all documents</div>
    </div>
    <div class="consent-2 flex-row gap-10">
      <input type="checkbox" [checked]="allTermsAccepted$ | async" />
      <div>General terms and conditions</div>
    </div>
    <div class="consent-3 flex-row gap-10">
      <input type="checkbox" [checked]="allTermsAccepted$ | async" />
      <div>Insurance product sales</div>
    </div>
  </div>
</div>
<div class="footer flex-row">
  <div class="footer-content flex-row gap-20">
    <img src="assets/insurwiz-logo-white.svg" alt="ins-logo" />
    <div class="bold">DEMO</div>
  </div>
</div>
