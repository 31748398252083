import { Component } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { MiddleComponent } from './middle/middle.component';
import { BottomComponent } from './bottom/bottom.component';
import { CommonModule } from '@angular/common';
import { SubmitComponent } from './submit/submit.component';

@Component({
  selector: 'app-insurance',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    MiddleComponent,
    BottomComponent,
    SubmitComponent,
  ],
  templateUrl: './insurance.component.html',
  styleUrl: './insurance.component.scss',
})
export class InsuranceComponent {
  constructor() {}

  scrollToSubmit(element: HTMLElement) {
    console.log('scrollToSubmit', element);
    element.scrollIntoView({ behavior: 'smooth' });
  }
}
