import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  removeAdditionalPassenger,
  updateAdditionalPassenger,
} from '../../index.actions';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-passenger',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './passenger.component.html',
  styleUrl: './passenger.component.scss',
})
export class PassengerComponent {
  @Input() passenger: any;
  @Input() index = -1;
  @Output() removeAdditionalPassengerEvent = new EventEmitter();

  validBirthDate = true;

  store = inject(Store);
  additionalPassengers$ = this.store.select('additionalPassengers');

  removeAdditionalPassenger(index: number): void {
    console.log('remove passenger', index);
    this.store.dispatch(removeAdditionalPassenger({ index }));
    this.removeAdditionalPassengerEvent.emit();
  }

  updateAdditionalPassengerName(index: number, name: string): void {
    console.log('update passenger name', index, name);
    this.store.dispatch(
      updateAdditionalPassenger({ index, passenger: { name } }),
    );
  }

  updateAdditionalPassengerBirthDate(index: number, birthDate: string): void {
    console.log('update passenger birthDate', index, birthDate);
    this.store.dispatch(
      updateAdditionalPassenger({ index, passenger: { birthDate } }),
    );
  }

  updateAdditionalPassenger(index: number, passenger: any): void {
    console.log('update passenger', index, passenger);
    this.store.dispatch(updateAdditionalPassenger({ index, passenger }));
  }

  setValidBirthDate(birthDate: string): void {
    this.validBirthDate = this.isValidBirthDate(birthDate);
  }

  validateBirthDate(index: number, birthDate: string): boolean {
    if (this.isValidBirthDate(birthDate)) {
      this.updateAdditionalPassengerBirthDate(index, birthDate);
      return true;
    }
    return false;
  }

  isValidBirthDate(birthDate: string): boolean {
    const validBirthDateRegex = /^\d{4}\.\d{2}\.\d{2}$/;
    return validBirthDateRegex.test(birthDate);
  }

  // onBirthDateMouseLeave() {
  //   this.store.dispatch(highlightSection({ currentHighlightedSection: 0 }));
  // }
}
