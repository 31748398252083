import { inject } from '@angular/core';
import { catchError, from, map, of, switchMap, withLatestFrom } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { InsuranceService } from './insurance/insurance.service';
import { Store } from '@ngrx/store';
import {
  flightRequestSuccess,
  flightReuestError,
  highlightSection,
  loadFlightsAction,
  submitInsuranceOutcome,
  submitInsuranceRequest,
} from './index.actions';

export const loadFlights = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    insuranceService = inject(InsuranceService),
  ) => {
    return actions$.pipe(
      ofType(loadFlightsAction),
      withLatestFrom(store.select('package')),
      switchMap(([param, pkg]) => {
        console.log('param', param);
        console.log('pkg', pkg);
        return insuranceService
          .getFlightData(param.flightNumber, param.date)
          .pipe(
            map((flights) => {
              console.log('sadf', flights);
              if (flights.length === 0) {
                throw new Error('No flights found');
              }
              return flightRequestSuccess({ flights, index: param.index });
            }),
            catchError((error: { message: string }) =>
              of(
                flightReuestError({ index: param.index, error: error.message }),
              ),
            ),
          );
      }),
    );
  },
  { functional: true },
);

// export const highlightMainPassengerSection = createEffect(
//   (actions$ = inject(Actions)) => {
//     return actions$.pipe(
//       ofType(flightRequestSuccess),
//       map((action) => {
//         return highlightSection({ currentHighlightedSection: 2 });
//       }),
//     );
//   },
//   { functional: true },
// );

export const submitInsurance = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    insuranceService = inject(InsuranceService),
  ) => {
    return actions$.pipe(
      ofType(submitInsuranceRequest),
      withLatestFrom(
        store.select('package'),
        store.select('flights'),
        store.select('mainPassenger'),
        store.select('additionalPassengers'),
      ),
      switchMap(
        ([param, pkg, flights, mainPassenger, additionalPassengers]) => {
          console.log('param', param);
          console.log('pkg', pkg);
          console.log('flights', flights);
          console.log('mainPassenger', mainPassenger);
          console.log('additionalPassengers', additionalPassengers);
          const payload = getInsurancePayload(
            pkg,
            flights,
            mainPassenger,
            additionalPassengers,
          );
          console.log('payload', payload);
          return insuranceService.submitInsurance(payload).pipe(
            map((insurance: any) => {
              localStorage.setItem(
                'insurance-number',
                insuranceService.getInsuranceNumber(insurance.id),
              );
              return submitInsuranceOutcome({ submit: 'success' });
            }),
            catchError((error: { message: string }) =>
              of(submitInsuranceOutcome({ submit: 'error' })),
            ),
          );
        },
      ),
    );
  },
  { functional: true },
);

export const effects = [{ loadFlights, submitInsurance }];

function getInsurancePayload(
  pkg: string,
  flights: any,
  mainPassenger: any,
  additionalPassengers: any,
) {
  const validFlights = flights
    .filter((flight: any) => {
      return (
        flight.flightNumberInput !== '' &&
        flight.dateInput !== '' &&
        flight.fetchedFlights.length > 0
      );
    })
    .map((flight: any) => {
      return flight.fetchedFlights[0];
    });
  const validAdditionalPassengers = additionalPassengers
    .filter((passenger: any) => {
      return passenger.name !== '' && passenger.birthDate !== '';
    })
    .map((passenger: any) => {
      return { name: passenger.name, dateOfBirth: passenger.birthDate };
    });

  return {
    flights: validFlights,
    insuredIndividual: { ...mainPassenger, dateOfBirth: 'demo-birth-date' },
    additionalPassengers: validAdditionalPassengers,
  };
}
