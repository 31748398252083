import { Component, OnInit, inject } from '@angular/core';
import { InsuranceService } from '../insurance.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-bottom',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './bottom.component.html',
  styleUrl: './bottom.component.scss',
})
export class BottomComponent implements OnInit {
  store = inject(Store);
  package$ = this.store.select('package');
  flights$ = this.store.select('flights');
  allTermsAccepted$ = this.store.select('allTermsAccepted');

  additionalPassengers$ = this.store.select('additionalPassengers');

  acceptAll = false;
  generalTerms = false;
  sales = false;

  constructor(readonly insuranceService: InsuranceService) {}

  ngOnInit(): void {
    this.package$.subscribe((pkg) => {
      this.insuranceService.package = pkg;
    });
  }

  selectAll() {
    // if (this.acceptAll) {
    //   this.generalTerms = false;
    //   this.sales = false;
    //   return;
    // }
    // this.generalTerms = true;
    // this.sales = true;
  }

  get policyDescription() {
    return this.package$.pipe(
      map((pkg) => (pkg === 'BASIC' ? 'Basic' : 'Maxi + flight cover')),
    );
  }

  get validPassengers() {
    return this.additionalPassengers$.pipe(
      map(
        (passengers) =>
          passengers.filter(
            (passenger: { name: string; birthDate: string }) =>
              passenger.name !== '' && passenger.birthDate !== '',
          ).length,
      ),
    );
  }

  // get flights where flightNumber and date and fetchedFlights are not null
  get validFlights() {
    return this.flights$.pipe(
      map((flights) =>
        flights.filter(
          (flight: {
            flightNumberInput: string;
            dateInput: string;
            fetchedFlights: [];
          }) =>
            flight.flightNumberInput !== null &&
            flight.dateInput !== null &&
            flight.fetchedFlights.length > 0,
        ),
      ),
    );
  }
}
