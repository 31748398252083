import { Routes } from '@angular/router';
import { InsuranceComponent } from './insurance/insurance.component';
import { StatusComponent } from './status/status.component';
import { SuccessComponent } from './success/success.component';

export const routes: Routes = [
  { path: '', component: InsuranceComponent },
  { path: 'insurance/:id', component: StatusComponent },
  { path: 'success', component: SuccessComponent },
];
