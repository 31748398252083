import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InsuranceService, Passenger } from '../insurance.service';
import { StandardFlightDto } from '../insurance.service';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import {
  addFlight,
  loadFlightsAction,
  updateMainPassenger,
  updateFlight,
  addAdditionalPassenger,
  highlightSection,
  acceptAllTerms,
} from '../../index.actions';
import { FlightComponent } from '../flight/flight.component';
import { PassengerComponent } from '../passenger/passenger.component';
import { NextStepButtonComponent } from '../next-step-button/next-step-button.component';

interface FlightTicket {
  flightCode: string;
  selectedDate: Date | null;
  selectedTime: string;
  departureAirport: string;
  arrivalAirport: string;
  arrivalTime: string;
  found: boolean | null;
}

@Component({
  selector: 'app-middle',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    FormsModule,
    MatMenuModule,
    CommonModule,
    MatProgressSpinnerModule,
    FlightComponent,
    PassengerComponent,
    NextStepButtonComponent,
  ],
  templateUrl: './middle.component.html',
  styleUrl: './middle.component.scss',
})
export class MiddleComponent {
  tickets: FlightTicket[] = [];
  additionalPassengers: Passenger[] = [];
  infoHover = false;
  validEmail = true;

  @ViewChild('mainPassenger') mainPassengerElement!: ElementRef;
  mainPassengerElementHeight = 0;
  mainPassengerElementWidth = 0;

  @ViewChild('flights') flightsElement!: ElementRef;
  flightsElementHeight = 0;
  flightsElementWidth = 0;

  @ViewChild('additionalPassengers') additionalPassengersElement!: ElementRef;
  additionalPassengersHeight = 0;
  additionalPassengersWidth = 0;

  isHovered = false;

  store = inject(Store);
  package$ = this.store.select('package');
  flights$ = this.store.select('flights');
  mainPassenger$ = this.store.select('mainPassenger');
  additionalPassengers$ = this.store.select('additionalPassengers');
  currentHighlightedSection$ = this.store.select('currentHighlightedSection');

  @Output()
  allSectionsFinished = new EventEmitter();

  constructor(readonly insuranceService: InsuranceService) {}

  ngAfterViewInit() {
    console.log(this.mainPassengerElement.nativeElement.clientHeight);
    setTimeout(() => {
      this.flightsElementHeight =
        this.flightsElement.nativeElement.clientHeight;
      this.flightsElementWidth = this.flightsElement.nativeElement.clientWidth;
      this.mainPassengerElementHeight =
        this.mainPassengerElement.nativeElement.clientHeight;
      this.mainPassengerElementWidth =
        this.mainPassengerElement.nativeElement.clientWidth;
      this.additionalPassengersHeight =
        this.flightsElement.nativeElement.clientHeight + 15;
      this.additionalPassengersWidth =
        this.additionalPassengersElement.nativeElement.clientWidth;
    }, 1000);
  }

  addFlight(): void {
    this.store.dispatch(
      addFlight({
        flight: {
          flightNumberInput: '',
          dateInput: '',
          loading: false,
          error: '',
          fetchedFlights: [] as StandardFlightDto[],
          selectedFlightIndex: -1,
        },
      }),
    );
    console.log('add flight');
    this.flightsElementHeight = this.flightsElementHeight + 72;
  }

  onRemoveFlight(): void {
    this.flightsElementHeight = this.flightsElementHeight - 72;
  }

  onRemoveAdditionalPassenger(): void {
    this.additionalPassengersHeight = this.additionalPassengersHeight - 64;
  }

  updateFlightNumber(
    index: number,
    flight: any,
    flightNumberInput: string,
  ): void {
    console.log('update flight number', index, flight, flightNumberInput);
    this.store.dispatch(
      updateFlight({ index, flight: { ...flight, flightNumberInput } }),
    );
  }

  updateFlightDate(index: number, flight: any, dateInput: string): void {
    console.log('update flight date', index, flight, dateInput);
    this.store.dispatch(
      updateFlight({ index, flight: { ...flight, dateInput } }),
    );
    this.flights$.subscribe((flights) => {
      console.log('flights', flights);
    });
  }

  onCalendarPick(e: MatDatepickerInputEvent<any>, index: number, flight: any) {
    const dateInput = e.value;
    console.log('selecting departure date', dateInput);
    this.updateFlightDate(index, flight, dateInput);
    this.flights$.subscribe((flights) => {
      console.log('flights', flights);
    });
    this.fetchFlights(flight.flightNumberInput, dateInput, index);
  }

  fetchFlights(flightNumber: string, date: Date, index: number) {
    console.log('fetchFlights');
    this.store.dispatch(loadFlightsAction({ flightNumber, date, index }));
  }

  setMainPassengerName(name: string) {
    console.log('setMainPassengerName', name);
    this.store.dispatch(updateMainPassenger({ passenger: { name } }));
    this.mainPassenger$.subscribe((mainPassenger) => {
      console.log('mainPassenger', mainPassenger);
    });
  }

  setMainPassengerEmail(email: string) {
    console.log('setMainPassengerEmail', email);
    this.store.dispatch(updateMainPassenger({ passenger: { email } }));
    this.mainPassenger$.subscribe((mainPassenger) => {
      console.log('mainPassenger', mainPassenger);
    });
  }

  getTimeFromDate(date: string) {
    return date.split('T')[1].slice(0, 5);
  }

  onMouseEnter(): void {
    this.isHovered = true;
  }

  onMouseLeave(): void {
    this.isHovered = false;
  }

  onFocusLeave(index: number) {
    this.insuranceService.additionalPassengers[index] =
      this.additionalPassengers[index];
    console.log(
      'added new additional passenger',
      this.additionalPassengers[index],
      index,
    );
  }

  onInfoHover() {
    this.infoHover = true;
  }

  outInfoHover() {
    this.infoHover = false;
  }

  addAdditionalPassenger(): void {
    this.store.dispatch(
      addAdditionalPassenger({ passenger: { name: '', birthDate: '' } }),
    );
    this.additionalPassengersHeight = this.additionalPassengersHeight + 64;
  }

  get isMaxiPackage$() {
    return this.package$.pipe(map((pkg) => pkg === 'MAXI'));
  }

  onEmailMouseLeave() {
    this.store.dispatch(highlightSection({ currentHighlightedSection: 3 }));
  }

  isValidFlightGiven() {
    return this.flights$.pipe(
      map((flights) => flights[0].fetchedFlights.length > 0),
    );
  }

  foundFlight$() {
    return this.flights$.pipe(
      map(
        (flights: any[]) =>
          flights.filter((f) => f.fetchedFlights.length > 0).length > 0,
      ),
    );
  }

  isValidMainPassenger$() {
    return this.mainPassenger$.pipe(
      map(
        (mainPassenger) =>
          mainPassenger.name &&
          // mainPassenger.name.includes(' ') &&
          mainPassenger.email &&
          this.isValidEmail(mainPassenger.email),
      ),
    );
  }

  isValidAdditionalPassenger$() {
    return this.additionalPassengers$.pipe(
      map(
        (passengers: any[]) =>
          passengers.filter(
            (p) =>
              p.name &&
              // p.name.includes(' ') &&
              p.birthDate &&
              this.isValidBirthDate(p.birthDate),
          ).length > 0,
      ),
    );
  }

  setValidEmail(email: string) {
    console.log('email', email, this.isValidEmail(email));
    this.validEmail = this.isValidEmail(email);
  }

  isValidEmail(email: string) {
    const validEmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return validEmailRegex.test(email);
  }

  isValidBirthDate(birthDate: string) {
    const validBirthDateRegex =
      /^\d{4}\.(0[1-9]|1[0-2])\.(0[1-9]|[12][0-9]|3[01])$/;
    return validBirthDateRegex.test(birthDate);
  }

  highlightSection(section: number) {
    this.store.dispatch(
      highlightSection({
        currentHighlightedSection: section,
      }),
    );
    if (section === 0) {
      console.log('highlighting section 0 - accept all terms');
      this.store.dispatch(acceptAllTerms({ allTermsAccepted: true }));
      this.allSectionsFinished.emit();
    }
  }

  isHighlighted(section: number) {
    return this.currentHighlightedSection$.pipe(
      map((currentHighlightedSection) => currentHighlightedSection === section),
    );
  }
}
