import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import {
  environment as env,
} from '../../environments/environment';

export interface CreateDemoInsurancePayload {
  flights: Array<StandardFlightDto>;
  insuredIndividual: InsuredIndividual;
  additionalPassengers: Array<AdditionalPassenger>;
}

export interface StandardFlightDto {
  flightNumber: number;
  airline: Airline;
  departure: Departure;
  arrival: Arrival;
}

interface Airline {
  iata: string;
}

interface Departure {
  date: string;
  airport: Airport;
}

interface Arrival {
  date: string;
  airport: Airport;
}

interface Airport {
  iata: string;
}

interface InsuredIndividual {
  name: string;
  dateOfBirth: string;
  email: string;
  bankAccountNumber: string;
  ackEmailSent: boolean;
}

export interface AdditionalPassenger {
  name: string;
  dateOfBirth: string;
}


export interface Flight {
  flightCode: string;
  departureDate: string;
}

export interface Passenger {
  name: string;
  email?: string;
  birthDate?: string;
}

export interface GetInsuranceResponse {
  id: number;
  payload: any;
  status: string;
  flights: Array<{
    flightCode: string;
    flightNumber: number;
    airlineIata: string;
    date: string;
    outcome: string;
    compensation: {
      status: string;
    };
  }>;
  compensation: any;
}

export interface DemoAppState {
  package: string;
  flights: {
    flightNumberInput: string;
    dateInput: string;
    loading: boolean;
    error: string;
    fetchedFlights: StandardFlightDto[];
    selectedFlightIndex: number;
  }[];
  mainPassenger: Passenger;
  additionalPassengers: Passenger[];
}

@Injectable({
  providedIn: 'root',
})
export class InsuranceService {
  package!: string;
  flights: StandardFlightDto[] = [];
  mainPassenger: Partial<Passenger> = { birthDate: '1986.08.12' };
  additionalPassengers: Passenger[] = [{ name: '', birthDate: '', email: 'passenger@web.com' }];

  passengers?: Passenger[] = [];

  DEV_URL = 'https://z5l0g8489g.execute-api.eu-central-1.amazonaws.com/dev';

  createdInsurance: any;
  insurance!: GetInsuranceResponse;

  constructor(private readonly http: HttpClient) {}

  getFlightData(flightCode: string, departureDate: Date) {
    const url = `${env.listUrl}/${flightCode.toUpperCase()}/${this.getShortDate(departureDate)}`;
    return this.http.get<StandardFlightDto[]>(url);
  }
  _getFlightData(param: any) {
    console.log("param", param);
    const flightCode = 'abc123';
    const departureDate = new Date();
    const url = `${env.listUrl}/${flightCode}/${this.getShortDate(departureDate)}`;
    return this.http.get<StandardFlightDto[]>(url);
  }

  submitInsurance(insurance: CreateDemoInsurancePayload) {
    console.log('submitting insurance', insurance);
    return this.http.post(env.baseUrl + '/flight-insurance/demo', insurance);
    // localStorage.setItem('insurance-number', this.getInsuranceNumber(response.id));
    // return response;
  }
  async getFlightInsurance(id: number) {
    this.insurance = await firstValueFrom(
      this.http.get<GetInsuranceResponse>(
        env.baseUrl + `/flight-insurance/find/${id}`
      )
    );
  }

  addFlight(flight: StandardFlightDto, index: number) {
    this.flights[index] = flight;
    console.log('added flight to insurance', this.flights);
  }

  updateFlight(flight: StandardFlightDto, index: number) {
    this.removeFlight(index);
    this.addFlight(flight, index);
  }

  removeFlight(index: number) {
    this.flights.splice(index, 1);
    console.log('removed flight from insurance', this.flights);
  }

  getCurrentFlight(index: number) {
    return this.flights[index];
  }

  getCurrentFlights() {
    return this.flights;
  }

  get filteredFlights() {
    return this.flights.filter(flight => flight);
  }

  get filteredAdditionalPassengers() {
    return this.additionalPassengers.filter(additionalPassenger => additionalPassenger.name && additionalPassenger.birthDate);
  }


  private getShortDate(date: Date) {
    const localDate = date.getTime() - date.getTimezoneOffset() * 60000;
    return new Date(localDate).toISOString().split('T')[0];
  }

  getInsuranceNumber(insuranceId: number) {
    //insurance number hack
    return `IW${insuranceId + 84737362}`;
  }




}
