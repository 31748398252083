import { Component } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { MiddleComponent } from './middle/middle.component';
import { BottomComponent } from './bottom/bottom.component';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { InsuranceService } from '../insurance/insurance.service';

@Component({
  selector: 'app-status',
  standalone: true,
  imports: [HeaderComponent, MiddleComponent, BottomComponent],
  templateUrl: './status.component.html',
  styleUrl: './status.component.scss',
})
export class StatusComponent {
  isLoading = true;

  constructor(
    private readonly insuranceService: InsuranceService,
    private route: ActivatedRoute,
  ) {}

  async ngOnInit() {
    let id = -1;
    this.route.paramMap.subscribe(async (params: ParamMap) => {
      id = +params.get('id')!.slice(2) - 84737362;
      this.isLoading = true;
      await Promise.all([
        this.insuranceService.getFlightInsurance(id),
        new Promise((res) => setTimeout(res, 1_200)),
      ]);
      this.isLoading = false;
      console.log('insurance', this.insuranceService.insurance);
    });
  }
}
