import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Store } from '@ngrx/store';
import {
  loadFlightsAction,
  removeFlight,
  updateFlight,
} from '../../index.actions';

@Component({
  selector: 'app-flight',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    FormsModule,
    MatMenuModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './flight.component.html',
  styleUrl: './flight.component.scss',
})
export class FlightComponent implements OnInit {
  @Input() flight: any;
  @Input() index = -1;
  @Output() removeFlightEvent = new EventEmitter();
  minDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  flightNumberInput = '';

  store = inject(Store);
  package$ = this.store.select('package');
  flights$ = this.store.select('flights');

  ngOnInit(): void {
    this.flightNumberInput = this.flight.flightNumberInput;
  }

  onInputChange(e: any) {
    this.flightNumberInput = e.target.value;
  }

  onCalendarPick(e: MatDatepickerInputEvent<any>, index: number) {
    const dateInput = e.value;
    console.log('selecting departure date', dateInput);
    this.updateFlightNumber(index, this.flightNumberInput);
    this.flights$.subscribe((flights) => {
      console.log('update fn');
      console.log('flights', flights);
      console.log(JSON.stringify(flights));
    });
    this.updateFlightDate(index, dateInput);
    this.flights$.subscribe((flights) => {
      console.log('flights', flights);
    });
    this.fetchFlights(this.flightNumberInput, dateInput, index);
  }

  updateFlightNumber(index: number, flightNumberInput: string): void {
    console.log('update flight number', index, flightNumberInput);
    this.store.dispatch(updateFlight({ index, flight: { flightNumberInput } }));
    // this.flights$.subscribe(flights => {
    //   console.log('flights', flights);
    // });
  }

  fetchFlights(flightNumber: string, date: Date, index: number) {
    console.log('fetchFlights');
    this.store.dispatch(loadFlightsAction({ flightNumber, date, index }));
  }

  updateFlightDate(index: number, dateInput: string): void {
    console.log('update flight date', index, dateInput);
    this.store.dispatch(updateFlight({ index, flight: { dateInput } }));
    this.flights$.subscribe((flights) => {
      console.log('flights', flights);
    });
  }
  removeFlight(index: number): void {
    console.log('remove flight', index);
    this.store.dispatch(removeFlight({ index }));
    this.removeFlightEvent.emit();
  }

  getTimeFromDate(date: string) {
    return date.split('T')[1].slice(0, 5);
  }
}
