import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { highlightSection } from '../../index.actions';
import { CommonModule } from '@angular/common';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-next-step-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './next-step-button.component.html',
  styleUrl: './next-step-button.component.scss',
})
export class NextStepButtonComponent {
  store = inject(Store);
  flights$ = this.store.select('flights');
  currentHighlightedSection$ = this.store.select('currentHighlightedSection');
}
