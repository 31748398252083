<div class="flex-row gap-20">
  <div class="ticket flex-row">
    <input #flightNumber type="text" class="flight-number" placeholder="Flight number" (input)="onInputChange($event)"
      [value]="flight.flightNumberInput" />
    <div class="departure-date">
      <mat-form-field>
        <input matInput style="display: none;" [matDatepicker]="picker" [min]="minDate"
          (dateChange)="onCalendarPick($event, index)">
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-icon *ngIf="flightNumberInput || flight.flightNumberInput" class="calendar-icon"
        (click)="picker.open()">event</mat-icon>
    </div>
  </div>
  <div class="loader" *ngIf="flight.loading">
    <mat-spinner [diameter]="60"></mat-spinner>
  </div>
  <div class="not-found" *ngIf="flight.error">
    We could not find fights on the chosen date!
  </div>

  <div class="ticket flex-row gap-10" *ngIf="flight.fetchedFlights.length > 0">
    <div class="departure-arrival flex-row gap-10">
      <div class="departure">
        <span class="departure-time">{{getTimeFromDate(flight.fetchedFlights[0].departure.date)}} </span>
        {{flight.fetchedFlights[0].departure.airport.iata}}
      </div>
      <img src="assets/arrow-right.svg" alt="arrow-right">
      <div class="arrival">
        {{flight.fetchedFlights[0].arrival.airport.iata}}
        <span class="arrival-time">{{getTimeFromDate(flight.fetchedFlights[0].arrival.date)}}</span>
      </div>
    </div>
  </div>
  <img class="remove-icon" src="assets/remove-icon.svg" alt="remove" (click)="removeFlight(index)">
</div>
