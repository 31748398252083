<div class="container">
  <div class="paragraphs flex-column gap-20">
    <div>
      We save the flight data from the insurance policy in a smart contract. If
      an insured event occurs, the system automatically starts the claims
      settlement process immediately.
    </div>
    <div>
      If you have any questions, you can contact us at: mail&#64;insurwiz.io
    </div>
  </div>
</div>
<div class="footer flex-row">
  <div class="footer-content flex-row gap-20">
    <img src="assets/insurwiz-logo-white.svg" alt="ins-logo" />
    <div class="bold">DEMO</div>
  </div>
</div>
