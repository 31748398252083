<div class="additional-passenger flex-row gap-20">
  <div class="main-passenger flex-row gap-40">
    <div class="flex-column gap-10">
      <input
        #additionalPassengerName
        type="text"
        [value]="passenger.name"
        (change)="
          updateAdditionalPassengerName(index, additionalPassengerName.value)
        "
      />
    </div>
    <div class="flex-column gap-10">
      <div class="flex-row gap-20">
        <input
          #additionalPassengerBirthDate
          type="text"
          placeholder="YYYY.MM.DD"
          [value]="passenger.birthDate"
          (keyup)="validateBirthDate(index, additionalPassengerBirthDate.value)"
          (change)="
            updateAdditionalPassengerBirthDate(
              index,
              additionalPassengerBirthDate.value
            )
          "
          (mouseleave)="setValidBirthDate(additionalPassengerBirthDate.value)"
        />
        <img
          class="remove-icon"
          src="assets/remove-icon.svg"
          alt="remove"
          (click)="removeAdditionalPassenger(index)"
        />
      </div>
      <div class="invalid-birthdate" *ngIf="!validBirthDate">
        Please, provide a valid birthdate!
      </div>
    </div>
  </div>
</div>
