import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import { StandardFlightDto } from './insurance/insurance.service';
import {
  addFlight,
  flightRequestSuccess,
  flightReuestError,
  removeFlight,
  updateMainPassenger,
  setPackage,
  updateFlight,
  removeAdditionalPassenger,
  updateAdditionalPassenger,
  addAdditionalPassenger,
  loadFlightsAction,
  submitInsuranceOutcome,
  highlightSection,
  acceptAllTerms,
} from './index.actions';

export interface State {
  package: string;
  flights: {
    flightNumberInput: string;
    dateInput: string;
    loading: boolean;
    error: string;
    fetchedFlights: StandardFlightDto[];
    selectedFlightIndex: number;
  }[];
  mainPassenger: {
    name: string;
    email: string;
  };
  additionalPassengers: {
    name: string;
    birthDate: string;
  }[];
  submit: SubmitState;
  currentHighlightedSection: number;
  allTermsAccepted: boolean;
}

export type SubmitState = 'idle' | 'submitting' | 'success' | 'error';

const packageReducer = createReducer(
  'MAXI',
  on(setPackage, (_state, { pkg }) => pkg),
);

const flightsReducer = createReducer(
  [
    {
      flightNumberInput: '',
      dateInput: '',
      loading: false,
      error: '',
      fetchedFlights: [] as StandardFlightDto[],
      selectedFlightIndex: -1,
    },
  ],
  on(addFlight, (state, { flight }) => [...state, flight]),
  on(removeFlight, (state, { index }) => state.filter((_, i) => i !== index)),
  on(updateFlight, (state, { index, flight }) =>
    state.map((f, i) => {
      // console.log('got', { ...state, ...flight });
      return i === index ? { ...f, ...flight } : f;
    }),
  ),
  on(loadFlightsAction, (state, { index }) =>
    state.map((f, i) => (i === index ? { ...f, loading: true } : f)),
  ),
  on(flightRequestSuccess, (state, { index, flights }) =>
    state.map((f, i) =>
      i === index
        ? { ...f, fetchedFlights: flights, loading: false, error: '' }
        : f,
    ),
  ),
  on(flightReuestError, (state, { index, error }) =>
    state.map((f, i) =>
      i === index ? { ...f, fetchedFlights: [], error, loading: false } : f,
    ),
  ),
);
const mainPassengerReducer = createReducer(
  { name: '', email: '' },
  on(updateMainPassenger, (state, { passenger }) => {
    return { ...state, ...passenger };
  }),
);

const additionalPassengersReducer = createReducer(
  [{ name: '', birthDate: '' }],
  on(addAdditionalPassenger, (state, { passenger }) => [...state, passenger]),
  on(removeAdditionalPassenger, (state, { index }) =>
    state.filter((_, i) => i !== index),
  ),
  on(updateAdditionalPassenger, (state, { index, passenger }) =>
    state.map((p, i) => {
      return i === index ? { ...p, ...passenger } : p;
    }),
  ),
);

const submitReducer = createReducer(
  'idle' as SubmitState,
  on(submitInsuranceOutcome, (_state, { submit }) => submit),
);

const highlightSectionReducer = createReducer(
  1,
  on(
    highlightSection,
    (_state, { currentHighlightedSection }) => currentHighlightedSection,
  ),
);

const allTermsAcceptedReducer = createReducer(
  false,
  on(acceptAllTerms, (_state, { allTermsAccepted }) => allTermsAccepted),
);

export const reducers: ActionReducerMap<State> = {
  package: packageReducer,
  flights: flightsReducer,
  mainPassenger: mainPassengerReducer,
  additionalPassengers: additionalPassengersReducer,
  submit: submitReducer,
  currentHighlightedSection: highlightSectionReducer,
  allTermsAccepted: allTermsAcceptedReducer,
};
