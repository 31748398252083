import { Component, OnInit, inject } from '@angular/core';
import { InsuranceService } from '../insurance.service';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { Store } from '@ngrx/store';
import {
  submitInsuranceOutcome,
  submitInsuranceRequest,
} from '../../index.actions';

@Component({
  selector: 'app-submit',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule, DialogComponent],
  templateUrl: './submit.component.html',
  styleUrl: './submit.component.scss',
})
export class SubmitComponent implements OnInit {
  store = inject(Store);
  submit$ = this.store.select('submit');
  package$ = this.store.select('package');
  allTermsAccepted$ = this.store.select('allTermsAccepted');

  constructor(
    readonly insuranceService: InsuranceService,
    private readonly router: Router,
    readonly dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.submit$.subscribe((submit) => {
      if (submit === 'success') {
        this.router.navigate(['success']);
      }
    });
  }

  onSubmit() {
    this.store.dispatch(submitInsuranceRequest());
    this.store.dispatch(submitInsuranceOutcome({ submit: 'submitting' }));
  }

  //TODO: onsubmit success redirect to /success route
  //modal on submit error

  openDialog() {
    this.dialog.open(DialogComponent, {
      data: {
        title: 'Please, add at least one valid flight before submitting!',
        subtitle: 'You can not choose the MAXI package without flights!',
      },
    });
  }
}
