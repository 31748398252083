<div class="container">
  <img id="logo" src="assets/demo-logo.png" alt="insurwiz-logo" />
  <span class="bold" id="demo">DEMO</span>
  <img id="flight-header" src="assets/flight-header.svg" alt="flight" />
  <div class="title">Status</div>
  <div class="sub-title">Insured flights</div>
  <div class="sub-sub-title">
    <span>Demo insurance policy number: </span>
    <span id="insurance-number">{{ insuranceNumber }}</span>
  </div>
</div>

<div fxLayout="row" fxLayoutAlign="center">
  <div></div>
</div>
