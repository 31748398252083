<div class="container">
  <div class="loader flex-row" *ngIf="(submit$ | async) === 'submitting'">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
  <div
    *ngIf="(package$ | async) === 'MAXI'"
    class="button flex-row gap-20"
    (click)="onSubmit()"
  >
    <!-- [ngClass]="{ 'blinking-button': allTermsAccepted$ | async }" -->
    <div class="bold">Submit</div>
    <img
      [ngClass]="{ 'blinking-arrow': (allTermsAccepted$ | async) }"
      src="assets/right-arrow.svg"
      alt="right-arrow"
    />
  </div>
</div>
