<div *ngIf="isLoading" class="flights-loader flex-row">
  <div class="loading-text">Loading your flights...</div>
  <mat-spinner [diameter]="30"></mat-spinner>
</div>

<div *ngIf="!isLoading" class="container flex-column">
  <div class="flights flex-column">
    <div class="flight flex-row gap-20" *ngFor="let flight of flights">
      <img src="assets/flight.svg" alt="" />
      <div class="flight-meta-data flex-column">
        <div class="flight-number">{{ flight.flightCode }}</div>
        <div class="date">
          {{ getShortDate(flight.date) }} {{ getShortTime(flight.date) }}
        </div>
      </div>

      <div class="status flex-row gap-20">
        <div class="status-text">{{ getStatus(flight.outcome) }}</div>
        <img
          *ngIf="flight.outcome === 'NO_INFO_YET'"
          src="assets/info-icon.svg"
          alt="info"
          class="info-icon"
          (mouseenter)="onMouseEnter()"
          (mouseleave)="onMouseLeave()"
        />
        <div *ngIf="isHovered" class="info-box">
          So far, so good. No sign of delays or cancellations.
        </div>
      </div>
    </div>

    <div class="horizontal-line"></div>
  </div>
</div>
