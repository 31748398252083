<div class="middle-container flex-column">
  <div
    #flights
    class="section"
    *ngIf="isMaxiPackage$ | async"
    [ngStyle]="{
      outline: (isHighlighted(1) | async) ? '2px solid black' : 'none'
    }"
  >
    <div
      *ngIf="!(isHighlighted(1) | async)"
      class="hide-section"
      style="position: relative"
    >
      <div
        class="filter"
        [style.width.px]="flightsElementWidth"
        [style.height.px]="flightsElementHeight"
      ></div>
    </div>
    <div class="header flex-row">
      <div
        *ngIf="isHighlighted(1) | async"
        style="position: relative"
        class="flex-row"
      >
        <div class="highlight" style="top: 50px">Add flights</div>
      </div>
      <div class="title">Add your flights</div>
      <img src="assets/flight.svg" alt="flight-icon" />
      <div class="horizontal-line"></div>
    </div>
    <div
      class="tickets flex-column gap-20"
      *ngFor="let flight of flights$ | async; let i = index"
    >
      <app-flight
        (removeFlightEvent)="onRemoveFlight()"
        [flight]="flight"
        [index]="i"
      ></app-flight>
    </div>
    <img
      class="add-icon"
      src="assets/add-icon.svg"
      alt="add"
      (click)="addFlight()"
    />
    <div class="flex-row next-step-button-container">
      <app-next-step-button
        (click)="highlightSection(2)"
        [ngStyle]="{
          visibility:
            (foundFlight$() | async) && (isHighlighted(1) | async)
              ? 'visible'
              : 'hidden'
        }"
      ></app-next-step-button>
    </div>
  </div>

  <div
    class="section"
    #mainPassenger
    [ngStyle]="{
      outline: (isHighlighted(2) | async) ? '2px solid black' : 'none'
    }"
  >
    <div
      *ngIf="!(isHighlighted(2) | async)"
      class="hide-section"
      style="position: relative"
    >
      <div
        class="filter"
        [style.width.px]="mainPassengerElementWidth"
        [style.height.px]="mainPassengerElementHeight"
      ></div>
    </div>
    <div class="header flex-row">
      <div
        *ngIf="isHighlighted(2) | async"
        style="position: relative"
        class="flex-row"
      >
        <div class="highlight" style="top: 30px">Add the policy holder</div>
      </div>
      <div class="title">Policy holder</div>
      <img
        class="info-icon"
        src="assets/info-icon.svg"
        alt="info"
        (mouseenter)="onMouseEnter()"
        (mouseleave)="onMouseLeave()"
      />
      <div class="info-box" *ngIf="isHovered">
        Enter your email address and we'll send you your demo policy and send
        you real-time updates like compensation notification if something
        happens with the insured flights.
      </div>
      <div class="horizontal-line"></div>
    </div>
    <div class="main-passenger flex-row gap-40">
      <div class="flex-column gap-10">
        <div class="flex-row">
          <div class="th" style="height: 23px">Name</div>
        </div>
        <input
          #mainPassengerName
          [value]="(mainPassenger$ | async).name"
          type="text"
          (change)="setMainPassengerName(mainPassengerName.value)"
        />
      </div>
      <div class="flex-column gap-10">
        <div class="flex-row" style="height: 23px">
          <div class="th">Email</div>
          <div class="email-info">
            <img
              src="assets/info-icon.svg"
              alt=""
              class="info-email-icon"
              style="margin-left: 10px"
              (mouseenter)="onInfoHover()"
              (mouseleave)="outInfoHover()"
            />
            <div *ngIf="infoHover" class="info-email-box">
              Please provide a valid email address, so we can send you status
              updates.
            </div>
          </div>
        </div>
        <input
          #mainPassengerEmail
          [value]="(mainPassenger$ | async).email"
          type="text"
          (keyup)="setMainPassengerEmail(mainPassengerEmail.value)"
          (mouseleave)="setValidEmail(mainPassengerEmail.value)"
        />
        <div class="invalid-email" *ngIf="!validEmail">
          Please, provide a valid email address!
        </div>
      </div>
    </div>
    <div class="flex-row next-step-button-container">
      <app-next-step-button
        (click)="highlightSection(3)"
        [ngStyle]="{
          visibility:
            (isValidMainPassenger$() | async) && (isHighlighted(2) | async)
              ? 'visible'
              : 'hidden'
        }"
      ></app-next-step-button>
    </div>
  </div>

  <div
    #additionalPassengers
    class="section"
    [ngStyle]="{
      outline: (isHighlighted(3) | async) ? '2px solid black' : 'none'
    }"
  >
    <div
      *ngIf="!(isHighlighted(3) | async)"
      class="hide-section"
      style="position: relative"
    >
      <div
        class="filter"
        [style.width.px]="additionalPassengersWidth"
        [style.height.px]="additionalPassengersHeight"
      ></div>
    </div>
    <div class="header flex-row">
      <div
        *ngIf="isHighlighted(3) | async"
        style="position: relative"
        class="flex-row"
      >
        <div class="highlight" style="top: 50px">Add other passengers</div>
      </div>
      <div class="title">Add travelers</div>
      <div class="horizontal-line"></div>
    </div>
    <div class="additional-passengers flex-column">
      <div class="flex-row gap-60">
        <div class="th" style="height: 23px; width: 250px">Name</div>
        <div class="th" style="height: 23px; width: 250px">Birth date</div>
      </div>
      <ng-container
        *ngFor="
          let additionalPassenger of additionalPassengers$ | async;
          let id = index
        "
      >
        <app-passenger
          (removePassengerEvent)="onRemoveAdditionalPassenger()"
          [passenger]="additionalPassenger"
          [index]="id"
        ></app-passenger>
      </ng-container>
    </div>
    <img
      src="assets/add-icon.svg"
      alt=""
      class="add-icon"
      (click)="addAdditionalPassenger()"
    />
    <div class="flex-row next-step-button-container">
      <app-next-step-button
        (click)="highlightSection(0)"
        [ngStyle]="{
          visibility:
            (isValidAdditionalPassenger$() | async) &&
            (isHighlighted(3) | async)
              ? 'visible'
              : 'hidden'
        }"
      ></app-next-step-button>
    </div>
  </div>
</div>
