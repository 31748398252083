<div class="header-container" [ngClass]="{ 'rounded-bottom': isRounded }">
  <img id="logo" src="assets/demo-logo.png" alt="insurwiz-logo" />
  <span class="bold" id='demo'><span *ngIf="isBranded">TAWUNIYA</span> DEMO</span>
  <img id="flight-header" src="assets/flight-header.svg" alt="flight" />
  <div class="title">Choose your travel insurance!</div>

  <div class="flex-row packages">
    <div class="package" (click)="selectPackage('BASIC')" [ngClass]="{selectedPackage: isBasic$ | async}">
      <div>BASIC</div>
      <div>travel insurance</div>
      <div class="horizontal-line"></div>
    </div>

    <div class="package" (click)="selectPackage('MAXI')" [ngClass]="{selectedPackage: isMaxi$ | async}">
      <div>MAXI</div>
      <div>travel insurance</div>
      <div class="horizontal-line"></div>
      <div style="font-weight: 500;">with Flight Disruption cover</div>
      <img id="info-icon" src="assets/info-icon.svg" alt="info" (mouseenter)="onMouseEnter()"
        (mouseleave)="onMouseLeave()">
      <div class="info-box" *ngIf="isHovered">Integrate our flight insurance
        solution into your travel insurance product. Flight
        disruption cover can be tailored to your needs, be the coverage flight delay over specified hours and/or flight
        cancellation.</div>
    </div>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <div [ngClass]="{ 'arrow-down': isRounded }"></div>
</div>
