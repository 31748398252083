<div class="insurance-container flex-row">
  <div class="parts flex-column">
    <app-header class="header" />
    <app-middle
      (allSectionsFinished)="scrollToSubmit(scrollTo)"
      class="middle"
    />
    <app-bottom class="bottom" />
  </div>
</div>

<div #scrollTo class="submit flex-row">
  <app-submit />
</div>
