import { Component } from '@angular/core';
import { InsuranceService } from '../insurance/insurance.service';

@Component({
  selector: 'app-success',
  standalone: true,
  imports: [],
  templateUrl: './success.component.html',
  styleUrl: './success.component.scss',
})
export class SuccessComponent {
  constructor(readonly insuranceService: InsuranceService) {}

  get insurance() {
    return this.insuranceService.insurance;
  }

  get insuranceNumber() {
    return localStorage.getItem('insurance-number');
  }
}
